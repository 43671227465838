<script>
import checkoutMixin from '@/v-shop/checkout/checkout-mixin'

export default {
	mixins: [checkoutMixin],
	inheritAttrs: false,
	props: {
		order: Object,
		formData: Object,
		paymentSubmitData: Object,
		transactionHash: String,
	},

	async mounted() {
		try {
			const { token, payerIdNumber, identificationType, paymentMethodId, issuerId, issuerName, ccbin } =
				this.formData

			const response = await this.checkout.stepSubmit({
				transactionHash: this.transactionHash,
				token,
				paymentMethodId,
				issuerId,
				issuerName,
				identificationType,
				identificationNumber: payerIdNumber,
				bin: ccbin,
			})

			const { success, data, isCheckoutError } = response

			if (success) {
				if (isCheckoutError) return
				this.$emit('approved', data.stepResponse)
			} else if (!isCheckoutError) {
				if (data.invalid) {
					this.$emit('invalid')
				} else {
					this.$emit('rejected', {
						rejectionCode: data.rejectionCode,
						rejectionMessage: data.rejectionMessage,
					})
				}
			}
		} catch (err) {
			await this.$shopApi
				.post({
					url: `/credit-card/transaction-failed`,
					loading: false,
					data: {
						transactionHash: this.transactionHash,
						error: String(err.stack || err.message),
					},
				})
				.catch(() => null)
			this.$emit('rejected', {
				rejectionCode: 'PAYMENT_REQUEST_ERROR',
				rejectionMessage: err.message,
			})
		}
	},
}
</script>

<template>
	<div></div>
</template>
