<script>
import checkoutMixin from '@/v-shop/checkout/checkout-mixin'
import { loadScript } from '@/utils/load-script'
const scriptSrc = 'https://live.decidir.com/static/v2.5/decidir.js'

export default {
	mixins: [checkoutMixin],
	inheritAttrs: false,
	props: {
		order: Object,
		formData: Object,
		paymentSubmitData: Object,
		transactionHash: String,
	},
	methods: {
		async insertIframe() {
			const { useCS, uid, orgId, merchantId } = this.paymentSubmitData

			document.getElementById('cs-iframe-decidir')?.remove()
			const iframe = document.createElement('iframe')
			iframe.src = 'about:blank'
			iframe.id = 'cs-iframe-decidir'
			iframe.style.display = 'none'

			return new Promise((resolve, reject) => {
				iframe.addEventListener('load', function () {
					if (useCS) {
						const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
						const query = `org_id=${orgId}&session_id=${merchantId}${uid}`
						iframeDoc.body.innerHTML =
							`<script src="https://h.online-metrix.net/fp/tags.js?${query}" type="text/javascript">` +
							`</` +
							`script>`
						iframeDoc.body.innerHTML += `<noscript><iframe style="width: 100px; height: 100px; border: 0; position: absolute; top: -5000px" src="https://h.online-metrix.net/fp/tags?${query}"></iframe></noscript>`
						setTimeout(() => resolve(iframe), 5000)
					} else {
						resolve(iframe)
					}
				})
				iframe.addEventListener('error', function () {
					reject(new Error(`Error al cargar el iframe de Decidir`))
				})
				document.body.appendChild(iframe)
			})
		},
		async generateToken(iframe) {
			const _document = iframe.contentDocument || iframe.contentWindow.document
			const _window = iframe.contentWindow

			const { productionMode, apiKey, useCS, doorNumber, uid } = this.paymentSubmitData
			await loadScript(scriptSrc, _document)
			await new Promise((resolve) => setTimeout(resolve, 1000))

			_window.Decidir.prototype._createUniqueIdentifier = function () {
				return uid
			}

			const url = productionMode
				? 'https://live.decidir.com/api/v2'
				: 'https://developers.decidir.com/api/v2'

			const decidir = new _window.Decidir(url, !useCS)
			decidir.setPublishableKey(apiKey)
			//decidir.setTimeout(5000)

			const inputs = {
				card_number: this.formData.number,
				security_code: this.formData.cvv,
				card_expiration_month: this.formData.expirationMonth,
				card_expiration_year: this.formData.expirationYear,
				card_holder_name: this.formData.payerName,
				card_holder_doc_type: 'dni',
				card_holder_doc_number: this.formData.payerIdNumber,
				card_holder_door_number: String(doorNumber),
				//device_unique_identifier: submitData.uid,
			}

			const formElm = _document.createElement('form')
			formElm.style.display = 'none'
			for (let [key, value] of Object.entries(inputs)) {
				let input = _document.createElement('input')
				input.setAttribute('value', value)
				input.setAttribute('type', 'text')
				input.setAttribute('name', key)
				input.setAttribute('data-decidir', key)
				formElm.appendChild(input)
			}

			const tokenResponse = await new Promise((resolve, reject) => {
				decidir.createToken(formElm, function (status, response) {
					if (status != 200 && status != 201) {
						let message = JSON.stringify({ status, response })
						reject(new Error(`Error al generar el token: ${message}`))
					} else {
						resolve(response)
					}
				})
			})

			return tokenResponse
		},
	},

	async mounted() {
		try {
			const iframe = await this.insertIframe()
			const tokenResponse = await this.generateToken(iframe)
			const response = await this.checkout.stepSubmit({
				transactionHash: this.transactionHash,
				token: tokenResponse,
			})

			const { success, data, isCheckoutError } = response

			if (success) {
				this.$emit('approved', { orderId: data.stepResponse.orderId })
			} else {
				if (isCheckoutError) return
				if (data.invalid) {
					this.$emit('invalid')
				} else {
					this.$emit('rejected', {
						rejectionCode: data.rejectionCode,
						rejectionMessage: data.rejectionMessage,
					})
				}
			}
		} catch (err) {
			await this.$shopApi
				.post({
					url: `/credit-card/transaction-failed`,
					loading: false,
					data: {
						transactionHash: this.transactionHash,
						error: String(err.stack || err.message),
					},
				})
				.catch(() => null)
			this.$emit('rejected', {
				rejectionCode: 'PAYMENT_REQUEST_ERROR',
				rejectionMessage: err.message,
			})
		}
	},

	beforeDestroy() {
		document.getElementById('cs-iframe-decidir')?.remove()
	},
}
</script>

<template>
	<div></div>
</template>
