const checkoutMixin = {
	inject: ['xcheckout'],
	computed: {
		checkout() {
			return this.xcheckout()
		},
	},
}

export default checkoutMixin
