<script>
import uniqBy from 'lodash/uniqBy'
import brandsLogos from '../brands-logos.js'
import modelBypassMixin from '@/utils/model-bypass-mixin'

export default {
	lang: 'shop',
	mixins: [modelBypassMixin(String, null)],
	props: {
		plansGroups: Array,
		currencySign: String,
		loading: Boolean,
		selectable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			brandCode: null,
			entityCode: null,
			planHash: null,
			watchChanges: false,
		}
	},
	computed: {
		brandsLogos() {
			return brandsLogos
		},
		brands() {
			let brands = uniqBy(
				this.plansGroups.map((x) => x.brand),
				'code'
			)
			return brands.sort((a, b) => {
				if (a.type == b.type) return a.name > b.name ? 1 : -1
				else return a.type == 'credit' ? -1 : 1
			})
		},
		selectedBrand() {
			return this.brandCode ? this.brands.find((x) => x.code == this.brandCode) : null
		},
		selectedBrandPlansGroups() {
			return this.selectedBrand
				? this.plansGroups.filter((x) => x.brand.code == this.selectedBrand.code)
				: []
		},
		entities() {
			return [...this.selectedBrandPlansGroups]
				.map((x) => x.entity)
				.sort((a, b) => {
					if (a.code == 'bank-all') return 1
					if (b.code == 'bank-all') return -1
					return a.displayName > b.displayName ? 1 : -1
				})
		},
		selectedEntity() {
			return this.entityCode ? this.entities.find((entity) => entity.code == this.entityCode) : null
		},
		selectedPlansGroup() {
			return this.selectedEntity
				? this.selectedBrandPlansGroups.find((x) => x.entity.code == this.selectedEntity.code)
				: null
		},
		plans() {
			return this.selectedPlansGroup?.plans || []
		},
		selectedPlan() {
			return this.planHash ? this.plans.find((x) => x.hash == this.planHash) : null
		},
		noEntityStep() {
			return this.entities.length == 1 && this.entityCode == 'bank-all'
		},
	},
	watch: {
		brands(value) {
			if (!this.watchChanges) return
			if (value.length == 1) {
				this.$nextTick(() => {
					this.brandCode = value[0].code
				})
			}
		},
		entities(value) {
			if (!this.watchChanges) return
			this.entityCode = null
			this.$nextTick(() => {
				if (value.length == 1) this.entityCode = value[0].code
				else this.$refs.entitiesContainer.focus()
			})
		},
		plans(value) {
			if (!this.watchChanges) return
			this.planHash = value.length ? value[0].hash : null
			this.$nextTick(() => {
				if (value.length) this.$refs.plansContainer.focus()
			})
		},
		brandCode() {
			if (!this.watchChanges) return
			this.onSelectionChanged()
		},
		entityCode() {
			if (!this.watchChanges) return
			this.onSelectionChanged()
		},
		planHash() {
			if (!this.watchChanges) return
			this.onSelectionChanged()
		},
	},
	methods: {
		onSelectionChanged() {
			if (this.selectedBrand && this.selectedEntity && this.selectedPlan) {
				this.iValue = this.selectedPlan.hash
			} else {
				this.iValue = null
			}
		},
		getPlanCardListeners(plan) {
			if (this.selectable) {
				return { click: () => (this.planHash = plan.hash) }
			} else {
				return {}
			}
		},
	},
	created() {
		this.watchChanges = false

		if (this.iValue) {
			let plansGroup = this.plansGroups.find((x) => x.plans.some((plan) => plan.hash == this.iValue))
			if (plansGroup) {
				this.brandCode = plansGroup.brand.code
				this.entityCode = plansGroup.entity.code
				this.planHash = this.iValue
			} else {
				this.iValue = null
			}
		} else {
			this.iValue = null
		}

		if (!this.iValue && this.brands.length == 1) {
			this.brandCode = this.brands[0].code
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.watchChanges = true
			this.onSelectionChanged()
		})
	},
}
</script>

<template>
	<div class="pa-10" v-if="loading">
		<div class="font-2 pa-4 text-center">{{ 'Cargando planes de pago' | lang }}</div>
		<v-progress-linear indeterminate color="success" />
	</div>
	<div v-else>
		<!-- BRAND SELECTORS -->
		<div class="pt-2">
			<div class="font-1 pb-3 pb-sm-6 d-flex align-center">
				<v-badge inline color="success" content="1" class="mr-1" />
				<div>{{ 'Seleccioná tu Tarjeta' | lang }}</div>
			</div>
			<v-item-group
				v-model="brandCode"
				class="d-flex align-sm-center flex-column flex-sm-row flex-sm-wrap"
				:mandatory="!!brandCode"
			>
				<v-item
					v-slot="{ active, toggle }"
					v-for="(brand, i) in brands"
					:key="brand.code"
					:value="brand.code"
				>
					<div
						class="d-flex pr-sm-8 pb-sm-6 pt-sm-0 py-4 brand-item align-center"
						@click="toggle"
						:class="{
							'brand-item--active': active,
							'brand-item--inactive': !active && brandCode,
							'brand-item--hasborder': i < brands.length - 1,
						}"
					>
						<div class="brand-item__logo" :class="{ 'elevation-6': active }">
							<Media :src="brandsLogos[brand.code]" contain height="100%" width="100%" />
						</div>
						<div class="pl-2">
							<div class="font-1 grey--text text--darken-3" :class="{ 'font-weight-bold': active }">
								{{ brand.name }}
							</div>
							<div class="font-0 grey--text">
								{{ brand.type == 'credit' ? $lang('Crédito') : $lang('Débito') }}
							</div>
						</div>
					</div>
				</v-item>
			</v-item-group>
		</div>
		<!-- ENTITY SELECTORS -->
		<div class="py-2" v-if="entities.length && !noEntityStep" ref="entitiesContainer" tabindex="-1">
			<div class="font-1 pb-3 d-flex align-center">
				<v-badge inline color="success" content="2" class="mr-1" />
				<div>{{ 'Seleccioná el Banco o Entidad emisora de tu Tarjeta' | lang }}</div>
			</div>
			<div class="radio-box">
				<v-radio-group v-model="entityCode" class="ma-0 pa-0" hide-details :mandatory="!!entityCode">
					<div
						class="radio-item radio-item--selectable d-flex align-start"
						:class="{ 'radio-item--selected': entityCode == entity.code }"
						v-for="entity of entities"
						:key="`${brandCode}_${entity.code}`"
						@click="entityCode = entity.code"
					>
						<v-radio :value="entity.code" />
						<div class="font-1 radio-item__title">{{ entity.displayName }}</div>
					</div>
				</v-radio-group>
			</div>
		</div>
		<!-- PLAN SELECTORS -->
		<div class="py-2" v-if="plans.length" ref="plansContainer" tabindex="-1">
			<div class="font-1 pb-3 d-flex align-center">
				<v-badge inline color="success" :content="noEntityStep ? '2' : '3'" class="mr-1" />
				<div v-if="selectable">{{ 'Seleccioná el plan de pago' | lang }}</div>
				<div v-else>{{ 'Planes de pago disponibles' | lang }}</div>
			</div>
			<div class="radio-box">
				<v-radio-group v-model="planHash" class="ma-0 pa-0" hide-details mandatory>
					<div
						class="radio-item d-flex align-start"
						:class="{
							'radio-item--selected': planHash == plan.hash,
							'radio-item--selectable': selectable,
						}"
						v-for="plan of plans"
						:key="`${brandCode}_${entityCode}_${plan.hash}`"
						v-on="getPlanCardListeners(plan)"
					>
						<v-radio :value="plan.hash" label="" v-if="selectable" />
						<div class="d-flex w100 align-center flex-wrap radio-item__title">
							<div class="d-flex align-center">
								<b class="nowrap mr-2">
									{{ selectedBrand.type == 'debit' ? $lang('1 pago de') : `${plan.qty}x` }}
								</b>
								<PriceText
									:amount="plan.installmentsAmount"
									:currency="currencySign"
									:sup-decimals="false"
								/>
							</div>
							<div
								v-if="plan.specialPlanName || plan.specialPlanLogo"
								class="d-flex align-center ml-2"
							>
								<Media
									v-if="plan.specialPlanLogo"
									width="80"
									height="30"
									:src="plan.specialPlanLogo"
								/>
								<div
									v-if="plan.specialPlanName"
									class="font-0 grey--text text--darken-2 font-weight-bold"
									:class="{ 'ml-1': !!plan.specialPlanLogo }"
								>
									{{ plan.specialPlanName }}
								</div>
							</div>
							<v-spacer :class="{ w100: $b.m }" />
							<div class="success--text font-1" v-if="plan.qty > 1 && !plan.surcharge">
								{{ 'Sin Interés' | lang }}
							</div>
							<div v-else-if="plan.qty > 1" class="grey--text font-1 d-flex align-center">
								<span class="mr-2">{{ 'Total' | lang }}</span>
								<PriceText :amount="plan.total" :currency="currencySign" :sup-decimals="false" />
							</div>
						</div>
					</div>
				</v-radio-group>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.radio-box {
	border-radius: 8px;
	border: 1px solid #ccc;
	border-top: none;
	overflow: hidden;
	.radio-item {
		.v-radio {
			// position: relative;
			// top: 5px;
			margin-bottom: 0 !important;
		}
		&__title {
			line-height: 24px;
		}
		background-color: #fff;
		padding: 12px 16px 12px 12px;
		border-left: 4px solid #ccc;
		cursor: pointer;
		border-top: 1px solid #bbb;
		&:hover {
			background-color: #f9f9f9;
		}
		&--selectable {
			&:hover {
				border-left: 4px solid #aaa;
			}
		}
		&--selected {
			background-color: #f5f5f5 !important;
			border-left: 4px solid var(--link) !important;
		}
	}
}

.brand-item {
	cursor: pointer;
	user-select: none;

	@media #{map-get($display-breakpoints, 'xs-only')} {
		&--hasborder {
			border-bottom: 1px solid #ddd;
		}
	}

	.brand-item__logo {
		border: 2px solid #ddd;
		cursor: pointer;
		padding: 4px;
		border-radius: 8px;
		width: 45px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		justify-items: center;
	}

	&--inactive {
		opacity: 0.8;
	}
	&:hover {
		opacity: 1;
		.brand-item__logo {
			border: 2px solid #bbb;
		}
	}
	&--active {
		opacity: 1;
		.brand-item__logo {
			border: 2px solid var(--link) !important;
		}
	}
}
</style>
