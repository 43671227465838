<script>
import { loadScript, unloadScript } from '@/utils/load-script'
import checkoutMixin from '@/v-shop/checkout/checkout-mixin'

const scriptSrc = 'https://sdk.mercadopago.com/js/v2'
export default {
	lang: 'shop',
	mixins: [checkoutMixin],
	props: {
		brand: Object,
		entity: Object,
		fixedPayerIdNumber: String,
	},
	data() {
		return {
			initLoading: true,
			mp: null,
			cardNumberElement: null,
			dateExpirationElement: null,
			securityCodeElement: null,
			currentBin: null,
			issuersItems: [],
			form: {},
			rules: {
				required: () => (value) => {
					value = String(value || '').trim()
					return value != '' ? true : this.$lang('Requerido')
				},
				maxLen: (max) => (value) => {
					value = String(value || '').trim()
					return value.length <= max ? true : this.$lang(`Máximo {max} caracteres`, { max })
				},
				numLen: (len) => (value) => {
					value = String(value || '').trim()
					return value.length == len ? true : this.$lang(`Ingresa {len} dígitos`, { len })
				},
				dni: () => (value) => {
					value = String(value || '').trim()
					if (!value) return true
					return value.match(/^[1-9]{1}[0-9]{6,7}$/) ? true : this.$lang('El DNI es inválido')
				},
				payerName: () => (value) => {
					value = String(value || '').trim()
					if (!value) return true
					return value.match(/^[a-záéíóúñ]+(\s+[a-záéíóúñ]+)+$/i) ? true : this.$lang('Nombre inválido')
				},
				numeric: () => (value) => {
					value = String(value || '').trim()
					if (!value) return true
					return value.match(/^[0-9]+$/) ? true : this.$lang('Ingresa solo números')
				},
			},
		}
	},
	computed: {},
	watch: {
		'form.issuerId'(value) {
			this.$assign(this.form, {
				issuerName: (value && this.issuersItems.find((x) => x.value == value)?.text) || null,
			})
		},
	},
	methods: {
		normalizePayerName() {
			return this.form.payerName
				.trim()
				.replace(/\s+/g, ' ')
				.toUpperCase()
				.replace(/Á/g, 'A')
				.replace(/É/g, 'E')
				.replace(/Í/g, 'I')
				.replace(/Ó/g, 'O')
				.replace(/Ú/g, 'U')
		},
		async onSubmit() {
			const requiredFieldsFilled = !!(
				this.form.issuerId &&
				this.form.issuerName &&
				this.form.identificationType &&
				this.form.payerName &&
				this.form.payerIdNumber &&
				this.form.paymentMethodId &&
				this.currentBin
			)

			if (this.$refs.form.validate() && requiredFieldsFilled) {
				const formData = {
					ccbin: this.currentBin,
					payerName: this.normalizePayerName(),
					payerIdNumber: this.form.payerIdNumber.trim(),
					issuerId: this.form.issuerId,
					issuerName: this.form.issuerName,
					identificationType: this.form.identificationType,
					paymentMethodId: this.form.paymentMethodId,
				}
				try {
					formData.token = await this.mp.fields.createCardToken({
						cardholderName: formData.payerName,
						identificationType: formData.identificationType,
						identificationNumber: formData.payerIdNumber,
					})
				} catch (err) {
					// nothing
				}

				if (formData.token?.id) {
					formData.expirationMonth = formData.token.expiration_month
					formData.expirationYear = formData.token.expiration_year
					formData.lastFourDigits = formData.token.last_four_digits
					return formData
				}
			}
			return { rejectionCode: 'VALIDATION_ERROR' }
		},
		initializeForm() {
			this.$assignDefaults(this.form, {
				paymentMethodId: null,
				issuerId: null,
				issuerName: null,
				identificationType: 'DNI',
				payerName: null,
				payerIdNumber: this.fixedPayerIdNumber || null,
			})

			this.mp = new window.MercadoPago(this.checkout.stepViewData.publicKey)
			this.cardNumberElement = this.mp.fields
				.create('cardNumber', { placeholder: 'Numero de la tarjeta' })
				.mount('mp-form-checkout__cardNumber')
			this.dateExpirationElement = this.mp.fields
				.create('expirationDate', { placeholder: 'MM/YY' })
				.mount('mp-form-checkout__expirationDate')
			this.securityCodeElement = this.mp.fields
				.create('securityCode', { placeholder: 'Codigo de Seguridad' })
				.mount('mp-form-checkout__securityCode')

			//this.mp.getIdentificationTypes().then((identificationTypes) => console.log({ identificationTypes }))

			this.cardNumberElement.on('binChange', async (data) => {
				try {
					let bin = data.bin
					if (!bin && this.form.paymentMethodId) {
						this.issuersItems = []
						this.$assign(this.form, {
							paymentMethodId: null,
							issuerId: null,
						})
					} else if (bin && bin != this.currentBin) {
						const { results } = await this.mp.getPaymentMethods({ bin })
						let [paymentMethod] = results
						this.$assign(this.form, {
							paymentMethodId: paymentMethod.id,
						})
						this.updatePCIFieldsSettings(paymentMethod)
						this.updateIssuer(paymentMethod, bin)
					}
					this.currentBin = bin
				} catch (e) {
					console.error('error getting payment methods: ', e)
				}
			})
		},
		updatePCIFieldsSettings(paymentMethod) {
			const { settings } = paymentMethod

			const cardNumberSettings = settings[0].card_number
			this.cardNumberElement.update({
				settings: cardNumberSettings,
			})

			const securityCodeSettings = settings[0].security_code
			this.securityCodeElement.update({
				settings: securityCodeSettings,
			})
		},
		async updateIssuer(paymentMethod, bin) {
			const { additional_info_needed, issuer } = paymentMethod
			let options = [issuer]
			if (additional_info_needed.includes('issuer_id')) {
				try {
					options = await this.mp.getIssuers({ paymentMethodId: paymentMethod.id, bin })
				} catch (err) {
					console.error('error getting issuers: ', err)
				}
			}
			this.issuersItems = options.map((x) => ({ value: x.id, text: x.name }))
			if (options.length == 1) {
				this.form.issuerId = options[0].id
			} else {
				let opt = this.form.issuerId ? options.find((x) => x.id == this.form.issuerId) : null
				this.form.issuerId = opt?.id || null
			}
		},
	},
	async mounted() {
		this.initLoading = true
		await loadScript(scriptSrc)
		this.initLoading = false
		this.$nextTick(() => {
			this.initializeForm()
		})
	},
	destroyed() {
		unloadScript(scriptSrc)
		window.MercadoPago = undefined
	},
}
</script>

<template>
	<v-progress-linear v-if="initLoading" color="link" indeterminate />
	<v-form v-else ref="form" @submit.prevent>
		<v-container :style="{ maxWidth: $b({ smAndUp: 480 }) }" :fluid="$b({ xs: true })">
			<v-row dense>
				<v-col cols="12">
					<div class="label">Número de la Tarjeta</div>
					<div id="mp-form-checkout__cardNumber" class="mpfield"></div>
				</v-col>
				<v-col cols="12">
					<div class="label">Banco Emisor</div>
					<Select
						class="card-issuer mb-2"
						v-model="form.issuerId"
						:items="issuersItems"
						:outlined="true"
						dense
						:rules="[rules.required()]"
						validate-on-blur
						:disabled="!issuersItems.length"
						placeholder="Selecciona el Banco Emisor..."
					/>
				</v-col>
				<v-col cols="12" sm="8">
					<div class="label">Nombre impreso en la Tarjeta</div>
					<TextField
						class="card-holder-name mb-2"
						:outlined="true"
						v-model="form.payerName"
						dense
						:rules="[rules.required(), rules.maxLen(30), rules.payerName()]"
						:placeholder="$lang('JUAN A PEREZ')"
						maxlength="30"
						validate-on-blur
						prepend-inner-icon="mdi-account"
					/>
				</v-col>
				<v-col cols="12" sm="4">
					<div class="label">Documento del titular</div>
					<TextField
						class="card-holder-id mb-2"
						type="tel"
						:outlined="true"
						dense
						v-model="form.payerIdNumber"
						:rules="[rules.required(), rules.numeric(), rules.dni()]"
						ref="test"
						placeholder="22333444"
						maxlength="8"
						prepend-inner-icon="mdi-card-account-details-outline"
						:disabled="!!fixedPayerIdNumber"
					/>
				</v-col>
				<v-col cols="12" sm="6">
					<div class="label">Fecha de expiración</div>
					<div id="mp-form-checkout__expirationDate" class="mpfield"></div>
				</v-col>
				<v-col cols="12" sm="6">
					<div class="label">Código de seguridad</div>
					<div id="mp-form-checkout__securityCode" class="mpfield"></div>
				</v-col>
			</v-row>
		</v-container>
	</v-form>
</template>

<style scoped>
.label {
	font-weight: bold;
	font-size: 14px;
	padding: 0 0 8px 0;
}

.mpfield {
	height: 40px;
	border: 1px solid #aaa;
	padding: 0 12px;
	border-radius: 4px;
	margin-bottom: 8px;
}
.v-text-field ::v-deep .v-messages__message {
	font-size: 13px;
	color: var(--error);
	padding: 4px 6px;
	border-radius: 6px;
	display: inline-block;
}

.v-text-field ::v-deep .v-text-field__details {
	padding: 0 !important;
}

.card-holder-name ::v-deep input {
	text-transform: uppercase;
}
</style>
